/* globals AbstractItemSelectorComponent, Binding, Configuration, Enums */
(function () {
  'use strict';

  const getOperationalPlanning = (type) => {
    if (type === 'ManualBacklog') {
      return;
    }
    return type === 'NetworkViewer' ? Enums.Plan.PlanType.DAILY_PLAN : type;
  };

  class WarehouseItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', '$q', 'warehouses'];
    }

    constructor ($element, $q, warehouses) {
      super($element);
      this.$q = $q;
      this.warehouses = warehouses;
    }

    _loadData () {
      // If the configuration is not defined then do no further processing
      if (_.isEmpty(this.configuration)) {
        this.itemSelectorInterface = undefined;
        return;
      }

      this.itemSelectorInterface = {
        items: () => this._warehousesByGroups(Configuration.scopes.current().code),
        preferences: {
          hive: this.configuration.type,
          key: this.metricType
        },
        // SIM: https://sim.amazon.com/issues/SOP-5535
        // The secondary node is not relevant to all flows in a plan that lists it as a granularity. To express that it is optional we
        // must include the empty string in the list as Plan Store uses that as the sentinel value for an unset granularity in storage.
        selectionChangeTransform: (selectedItems) => {
          const list = selectedItems.map((item) => this.scopeInfo ? { id: item.id, scope: item.scope } : item.id);
          if (this.secondaryNodeSelection()) {
            list.unshift('');
          }
          return { list };
        }
      };
    }

    _warehousesByGroups (scopeCode) {
      const scopes = this.secondaryNodeSelection() ? Configuration.transferScopeMappings[scopeCode] : [scopeCode];
      return this.$q.all({ groups: this._getAllGroupsInRegion(scopes), warehouses: this._getAllWarehousesInRegion(scopes) }).then((data) => {
        const groups = data.groups;
        const warehouses = data.warehouses;
        return _.filter(
          groups.map(
            (group) => ({
              id: group.groupId,
              items: _.filter(warehouses, (warehouse) => _.includes(warehouse.groups, group.groupId)).map((item) => _.set(item, 'id', item.warehouseId)),
              name: group.groupId
            })
          ),
          (group) => group.items.length > 0
        );
      });
    }

    _getAllGroupsInRegion (scopes) {
      return this.$q.all(_.map(scopes, (scope) => this._getGroupsForScope(scope)))
        .then((groups) => _.uniqBy(_.flatten(groups), 'groupId'));
    }

    _getGroupsForScope (scope) {
      const groupOptions = { asOfDate: this.configuration.date, scope: scope };
      return this.warehouses.groups(groupOptions);
    }

    _getAllWarehousesInRegion (scopes) {
      return this.$q.all(_.map(scopes, (scope) => this._getWarehousesForScope(scope)))
        .then((warehouses) => _.uniqBy(_.flatten(warehouses), 'warehouseId'));
    }

    _getWarehousesForScope (scope) {
      const warehouseOptions = {
        asOfDate: this.configuration.date,
        operationalCategory: getOperationalPlanning(this.configuration.type),
        scope: scope
      };
      return this.warehouses.warehouse().list(warehouseOptions);
    }

    secondaryNodeSelection () {
      return this.metricType === 'secondaryNode';
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (Binding.changes.has(changes.configuration)) {
        this._loadData();
      }
    }
  }

  angular.module('application.components')
    .component('warehouseItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({ metricType: '<', scopeInfo: '<' }),
      controller: WarehouseItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
