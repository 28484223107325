(function () {
  'use strict';

  class GrainMappingRatioGroupModalController {
    getDestinationKeyPath (grainId) {
      return `destinationKey.${grainId}`;
    }

    isValidGrainMappingRatioGroup () {
      return !_.isEqual(this.initialGrainMappingRatioGroup, this.grainMappingRatioGroup);
    }

    $onInit () {
      this.grainMappingRatioGroup = this.resolve.grainMappingRatioGroup;
      this.isCreate = this.resolve.isCreate;
      this.type = this.resolve.type;

      this.initialGrainMappingRatioGroup = _.cloneDeep(this.resolve.grainMappingRatioGroup);
    }

    onSubmit () {
      this.modalInstance.close(this.grainMappingRatioGroup);
    }
  }

  /**
   * Component for the grain mapping ratio group modals.
   * @name application.components.grain-mapping-ratio-group-modal
   */
  angular.module('application.components')
    .component('grainMappingRatioGroupModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with four properties:
         *    grainMappingRatioGroup - The base grain mapping ratio group object to be viewed and augmented.
         *    isCreate - Boolean to determine if the scenario is create or edit.
         *.   type - String to determine what type of grain mapping ratio group is being viewed.
         */
        resolve: '<'
      },
      controller: GrainMappingRatioGroupModalController,
      controllerAs: 'modalCtrl',
      templateUrl: 'templates/components/grain-mapping-ratio-group-modal.component.html'
    });
})();
