/* globals Enums, GrainMappingRatioGroup, Ready */
(function () {
  'use strict';

  class GrainMappingRatioGroupListController {
    static get $inject () {
      return ['planConfiguration', '$scope', '$uibModal'];
    }

    constructor (planConfiguration, $scope, $uibModal) {
      this.planConfiguration = planConfiguration;
      this.$scope = $scope;
      this.$uibModal = $uibModal;

      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.grainMappingRatio.mapping),
        isLoadingFn: () => this.loading
      });
    }

    _loadData () {
      this.loading = true;
      this.grainMappingRatio = { mapping: [] };
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.planConfiguration[this.ratioType.id]()
        .list()
        .then((data) => this.grainMappingRatio = data)
        .finally(() => this.loading = false);
    }

    getSourceKeyPath (grainId) {
      return `sourceKey.${grainId}`;
    }

    isAddDisabled () {
      return this.loading || _.isEmpty(this.grainMappingRatio.mapping);
    }

    openModal (event, isCreate, grainMappingRatioGroup = GrainMappingRatioGroup.create(this.grainMappingRatio.granularity)) {
      if (!_.isNil(event)) {
        event.stopPropagation();
      }
      this.$uibModal
        .open({
          component: 'grainMappingRatioGroupModal',
          resolve: {
            grainMappingRatioGroup: () => _.cloneDeep(grainMappingRatioGroup),
            isCreate: () => isCreate,
            type: () => this.ratioType
          },
          size: Enums.ModalSize.EXTRA_LARGE
        });
    }

    $onInit () {
      this._loadData();
    }
  }

  /**
   * Component to display list of grain mapping ratio groups
   * @name application.components.grain-mapping-ratio-group-list
   * @example
   * <grain-mapping-ratio-group-list></grain-mapping-ratio-group-list>
   */
  angular.module('application.components')
    .component('grainMappingRatioGroupList', {
      bindings: {
        /*
         * @ratioType String that determines which type of grain mapping ratio group
         * is being displayed.
         */
        ratioType: '<'
      },
      controller: GrainMappingRatioGroupListController,
      templateUrl: 'templates/components/grain-mapping-ratio-group-list.component.html'
    });
})();
