/* globals Configuration, Enumeration, Enums */
(function () {
  'use strict';

  const GRAIN_MAPPINGS_TYPES = Enumeration.create('GROUP', 'RATIO').asStringValue();
  // This construct should not be statically defined.
  // TODO: Fetch the Tenant-MappingGroups via API call. SIM: https://sim.amazon.com/issues/SOP-11466
  const GRAIN_MAPPINGS = Object.freeze({
    CAPACITY: [
      {
        id: Enums.GrainIdentity.binTypeGroup,
        type: GRAIN_MAPPINGS_TYPES.GROUP
      },
      {
        id: Enums.GrainIdentity.networkInventoryGroup,
        type: GRAIN_MAPPINGS_TYPES.GROUP
      }
    ],
    SANDOP: [
      {
        id: Enums.GrainIdentity.planningGroup,
        type: GRAIN_MAPPINGS_TYPES.GROUP
      }
    ]
  });

  class GrainMappingConfigurationController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.$scope = $scope;
      this.$onInit();
    }

    $onInit () {
      const grainMappingOptions = GRAIN_MAPPINGS[Configuration.scopes.current().tenant.id];
      this.$scope.model = {
        grainMappingOptions: grainMappingOptions,
        selectedGrainMapping: _.head(grainMappingOptions)
      };

      this.$scope.actions = {
        displayNameFn: (value) => _.defaultTo(value.name, _.startCase(value.id)),
        isSelectionGroup: () => this.$scope.model.selectedGrainMapping.type === GRAIN_MAPPINGS_TYPES.GROUP,
        isSelectionRatio: () => this.$scope.model.selectedGrainMapping.type === GRAIN_MAPPINGS_TYPES.RATIO
      };
    }
  }
  angular.module('application.controllers').controller('GrainMappingConfigurationController', GrainMappingConfigurationController);
})();
