(function () {
  'use strict';

  class GrainMappingRatioGroup {
    constructor (granularity, sourceKey, ratioValues) {
      this.destinationGrains = granularity.destinationGrain;
      this.sourceGrains = granularity.sourceGrain;
      this.sourceKey = sourceKey;
      this.ratioValues = ratioValues;
    }

    static create (granularity, sourceKey, ratioValues) {
      return new GrainMappingRatioGroup(granularity, sourceKey, ratioValues);
    }

    // Currently, the response for grain mapping ratios uses a list for ratio values
    // instead of a map. Consequently, this helper method is used to assist with the
    // linear searching of "ratioValues".
    _getDestinationKey (...destinationGrainValues) {
      return destinationGrainValues.reduce((destinationKey, destinationGrainValues, index) => _.set(destinationKey, this.destinationGrains[index].id, destinationGrainValues), {});
    }

    addRatio (ratioValue, ...destinationGrainValues) {
      // If the ratio is already present, set it to the provided value.
      const foundRatioValue = this.findRatio(...destinationGrainValues);
      if (!_.isNil(foundRatioValue)) {
        foundRatioValue.ratio = ratioValue;
        return;
      }
      // Otherwise add the ratio.
      this.ratioValues.push({
        destinationKey: this._getDestinationKey(...destinationGrainValues),
        ratio: ratioValue
      });
    }

    findRatio (...destinationGrainValues) {
      return this.ratioValues.find((ratioValue) => _.isEqual(ratioValue.destinationKey, this._getDestinationKey(...destinationGrainValues)));
    }

    removeRatio (...destinationGrainValues) {
      _.remove(this.ratioValues, (ratioValue) => _.isEqual(ratioValue.destinationKey, this._getDestinationKey(...destinationGrainValues)));
    }
  }
  window.GrainMappingRatioGroup = Object.freeze(GrainMappingRatioGroup);
})();
