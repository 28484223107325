/* globals AbstractElementComponent, Binding, Comparison, Enums, TreeNode */
(function () {
  'use strict';

  class ItemSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$element', 'preferences', '$scope'];
    }

    constructor (alerts, $element, preferences, $scope) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.root) || _.isEmpty(this.root.children),
        isLoadingFn: () => !_.isNil(this.interface) && _.isNil(this.root),
        isWaitingFn: () => _.isNil(this.interface)
      });
      this.alerts = alerts;
      this.preferences = preferences;
      this.$scope = $scope;
      this.selectedItems = [];
    }

    _applyResponseTransform (items, defaultFn) {
      return _.has(this.interface, 'selectionChangeTransform') ? this.interface.selectionChangeTransform(items, this.root) : { list: defaultFn(items) };
    }

    _isSelectAllEligible () {
      return _.has(this.interface, 'canSelectAllItems') ? this.interface.canSelectAllItems(this.initialSelections) : !Array.isArray(this.initialSelections);
    }

    _loadData () {
      this.selectedItems.length = 0;
      if (this.state.isWaiting()) {
        return;
      }

      // Clear existing state
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.root = undefined;
      if (_.isNil(this.initialSelections) && this.arePreferencesEnabled()) {
        this.preferences.get(this.preferences.USER, this.interface.preferences.hive, this.interface.preferences.key)
          .then((preferences) => {
            if (!_.isNil(preferences)) {
              this.initialSelections = preferences.selections;
            }
          })
          .finally(() => this._loadGroupsAndItems(true));
      } else {
        this._loadGroupsAndItems(this.disablePreferences);
      }
    }

    _loadGroupsAndItems (triggerSelectionChange) {
      // Load data
      const allItems = [];
      this.interface.items()
        .then((groups) => {
          this.root = TreeNode.create({ id: 'root' });
          groups.forEach((group) => {
            const node = TreeNode.create(group, this.root);
            node.hide(groups.length === 1);
            group.items.forEach((item) => TreeNode.create(item, node));
            if (this.isSortEnabled()) {
              node.sortChildren();
            }
            allItems.push(...node.children);
            return node;
          });
          this.root.sortChildren();
          this._setInitialSelections();
          if (_.isFunction(this.onLoadItems)) {
            this.onLoadItems(this._applyResponseTransform(allItems, (items) => _.map(items, 'source')));
          }
          // Select all items if _isSelectAllEligible() is truthy and noDefaultSelections is falsy
          if (this._isSelectAllEligible() && !this.noDefaultSelections) {
            // Mimic a user click
            this.root.isSelected = true;
            this.onNodeClick(this.root);
          } else if (triggerSelectionChange) {
            this._triggerSelectionChange();
          }
        })
        // On error, set root to an empty object for state transition to no-data
        .catch((error) => {
          this.root = {};
          this.alerts.error(error);
        });
    }

    _nodeSelectCallback () {
      this.selectedItems = _.map(this.root.getSelectedNodes().filter((node) => node.isLeaf()), 'source');
    }

    _setInitialSelections () {
      if (_.isEmpty(this.initialSelections) || _.isEmpty(this.root)) {
        return;
      }
      this.root.select(false, this._nodeSelectCallback.bind(this));
      this.root.traverse((node) => {
        if (_.some(this.initialSelections, (value) => Comparison.areIdentityEqual(node.source, value))) {
          node.select(true, this._nodeSelectCallback.bind(this));
        }
      });
    }

    _triggerSelectionChange () {
      this.onSelectionChange(this._applyResponseTransform(this.selectedItems, (items) => Array.from(items)));
    }

    arePreferencesEnabled () {
      return !this.disablePreferences && _.isString(_.get(this.interface, 'preferences.hive')) && _.isString(_.get(this.interface, 'preferences.key'));
    }

    isDisplayModeCompact () {
      return this.interface.displayMode === 'compact';
    }

    isSearchEnabled () {
      return !this.disableSearch;
    }

    isSortEnabled () {
      return this.interface ? !this.interface.disableSort : true;
    }

    isSingleItemOnly () {
      return !!this.singleItemOnly;
    }

    onNodeClick (node, only) {
      const nodeSelectState = only || node.isSelected;
      if ((only || this.isSingleItemOnly()) && !_.isEmpty(this.selectedItems)) {
        this.root.select(false, this._nodeSelectCallback.bind(this));
      }
      node.select(nodeSelectState, this._nodeSelectCallback.bind(this));
      this._triggerSelectionChange();
    }

    onSearchInputChange (input = '') {
      const index = input.indexOf(':');
      const key = index <= 0 ? 'name' : input.slice(0, index);
      const value = index === -1 ? input : input.slice(index + 1);
      this.root.filter(_.isEmpty(value) ? () => false : (node) => !node.isMatch(key, value));
    }

    $onChanges (changes) {
      if (!this.state.isInitialized() || this.state.isWaiting()) {
        return;
      }

      if (Binding.changes.has(changes.interface)) {
        this._loadData();
      } else if (Binding.changes.has(changes.initialSelections)) {
        this._setInitialSelections();
      }
    }
  }

  angular.module('application.components')
    .component('itemSelector', {
      bindings: {
        /*
         * @disablePreferences (Optional) When true, no preferences will be loaded and applied on the list of items.
         * Also, save-preferences and manage-preferences components will be hidden.
         */
        disablePreferences: '<',
        /*
         * @disableSearch (Optional) When true, no search box will be displayed for filtering the list of items.
         */
        disableSearch: '<',
        /*
         * @initialSelections (Optional) is an array of strings or objects with ids that correspond to what items
         * should be initially selected.
         */
        initialSelections: '<',
        /*
         * @interface is expected to be an object with two methods:
         *   disableSort - (Optional) if set to true, the group items order will not be sorted.
         *   displayMode - (Optional) if 'compact', UI elements are displayed with compact visual settings.
         *   items() - returns a promise that provides data in the expected item selector format:
         *     [
         *       {
         *         id: 'Group 1 Id',
         *         items: [{ name: 'Item 1 Name' }, ...] || ['Item 1 Name', ...],
         *         name: 'Group 1 Name'
         *       },
         *       ...
         *     ]
         *   canSelectAllItems(initialSelections) - (Optional) returns true if all items can be selected based on initialSelections
         *   selectionChangeTransform(selectedItems, root) - (Optional) returns selection change response object based on selectedItems & root selection
         *   preferences - (Optional) object for specifying the hive and key for personal/default preferences retrieval and storage:
         *     {
         *        hive: 'String',
         *        key: 'String'
         *     }
         */
        interface: '<',
        /*
         * @noDefaultSelections (Optional) When true, no items will be selected by default.
         * If falsey then all items will be selected by default, unless there are @initialSelections defined.
         */
        noDefaultSelections: '<',
        /*
         * @onLoadItems (Optional) is a callback to the parent whenever the list of items is fetched from the server.
         * It provides the list of all possible items to select as the named argument: @list.
         */
        onLoadItems: '&',
        /*
         * @onSelectionChange is a callback to the parent whenever the set of selected items changes.
         * It provides the list of selected items as the named argument: @list.
         */
        onSelectionChange: '&',
        /*
         * @singleItemOnly (Optional) When true, only a single item can be selected at a time. Otherwise, any number of items can be selected.
         */
        singleItemOnly: '<'
      },
      controller: ItemSelectorController,
      templateUrl: 'templates/components/item-selector.component.html'
    });
})();
