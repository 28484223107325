/* globals Comparison, Enumeration, Enums, Identity */
(function () {
  'use strict';
  const DISPLAY_WIDTH_PROPERTY = 'displayWidth';

  const ARC_GRAINS = [];
  const GRAIN_TO_GROUPS_MAP = {};
  const KNOWN_GRAIN_MAP = {};
  const NON_EDITABLE_GRAINS = [];
  const NON_NATIVE_GRAINS = [];
  const FILTER_GRAIN_INDEX = Enumeration
    .create(
      Enums.GrainIdentity.node,
      Enums.GrainIdentity.secondaryNode,
      Enums.GrainIdentity.scopeGroup,
      Enums.GrainIdentity.secondaryScopeGroup,
      Enums.GrainIdentity.productLine,
      Enums.GrainIdentity.sortType,
      Enums.GrainIdentity.binType,
      Enums.GrainIdentity.businessEntity,
      Enums.GrainIdentity.networkInventoryGroup,
      Enums.GrainIdentity.usageType,
      Enums.GrainIdentity.networkCapacityGroup,
      Enums.GrainIdentity.binTypeGroup,
      Enums.GrainIdentity.productLineOwner,
      Enums.GrainIdentity.origin,
      Enums.GrainIdentity.lag,
      Enums.GrainIdentity.networkInventoryGroupNetworkPlanning,
      Enums.GrainIdentity.businessChannel,
      Enums.GrainIdentity.businessUnit,
      Enums.GrainIdentity.customerType,
      Enums.GrainIdentity.daysSupply,
      Enums.GrainIdentity.pharmacySite,
      Enums.GrainIdentity.prescriptionType,
      Enums.GrainIdentity.subMetric,
      Enums.GrainIdentity.subTeam,
      Enums.GrainIdentity.sortCenter,
      Enums.GrainIdentity.processType,
      Enums.GrainIdentity.windowType,
      Enums.GrainIdentity.feederType
    )
    .asIndexValue(0);

  const resolveName = (grain) => {
    if (_.isNil(grain)) {
      return grain;
    }
    if (_.isString(grain)) {
      return _.get(KNOWN_GRAIN_MAP, `${grain}.name`, grain);
    }
    if (_.has(grain, 'name')) {
      return grain.name;
    }
    if (_.has(grain, 'id')) {
      return _.get(KNOWN_GRAIN_MAP, `${grain.id}.name`, grain);
    }
    if (_.has(grain, 'key')) {
      return _.get(KNOWN_GRAIN_MAP, `${grain.key}.name`, grain);
    }
  };

  const resolveDisplayWidth = (grain, id) => {
    if (_.isEmpty(grain)) {
      return Enums.DisplayWidth.SMALL;
    }
    if (_.isString(_.get(grain, DISPLAY_WIDTH_PROPERTY))) {
      return grain[DISPLAY_WIDTH_PROPERTY];
    }
    return _.get(KNOWN_GRAIN_MAP, `${id}.${DISPLAY_WIDTH_PROPERTY}`, Enums.DisplayWidth.LARGE);
  };

  class Grain {
    constructor (source) {
      this.id = Identity.of(source);
      this.name = resolveName(source);
      this.displayWidth = resolveDisplayWidth(source, this.id);
      this.filterIndex = FILTER_GRAIN_INDEX[this.id];
    }

    static create (source) {
      return new Grain(source);
    }

    static get known () {
      return KNOWN_GRAIN_MAP;
    }

    static isKnownGrain (grain) {
      return _.has(KNOWN_GRAIN_MAP, Identity.of(grain));
    }

    getGroupGrains () {
      return _.get(GRAIN_TO_GROUPS_MAP, this.id, []);
    }

    isArc () {
      return _.includes(ARC_GRAINS, this.id);
    }

    isEditable () {
      return !_.includes(NON_EDITABLE_GRAINS, this.id);
    }

    isEntityGrain () {
      return this.equals(KNOWN_GRAIN_MAP.entity);
    }

    isViewEdit () {
      return this.isEditable() && !this.equals(KNOWN_GRAIN_MAP.metric);
    }

    isKnownGrain () {
      return Grain.isKnownGrain(this.id);
    }

    isMetricGrain () {
      return this.equals(KNOWN_GRAIN_MAP.metric);
    }

    isScopeGrain () {
      return this.equals(KNOWN_GRAIN_MAP.scope) || this.equals(KNOWN_GRAIN_MAP.secondaryScope);
    }

    isNative () {
      return !_.includes(NON_NATIVE_GRAINS, this.id);
    }

    isEditTemplate () {
      return this.isEditable() && !this.equals(KNOWN_GRAIN_MAP.draft);
    }

    equals (compare) {
      return Comparison.areIdentityEqual(this, compare);
    }
  }
  Object.freeze(Grain);

  const addKnownGrain = (id, name, displayWidth) => KNOWN_GRAIN_MAP[id] = Grain.create({ displayWidth, id, name });

  addKnownGrain(Enums.GrainIdentity.binType, 'Bin Type', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.binTypeGroup, 'Bin Type Group', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.businessChannel, 'Business Channel', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.businessEntity, 'Business Entity', Enums.DisplayWidth.MEDIUM_SMALL);
  // businessEntityGroup is a virtual grain (aggregate of PLs)
  addKnownGrain(Enums.GrainIdentity.businessEntityGroup, 'Business Entity', Enums.DisplayWidth.MEDIUM_SMALL);
  addKnownGrain(Enums.GrainIdentity.businessUnit, 'Business Unit', Enums.DisplayWidth.MEDIUM_SMALL);
  addKnownGrain(Enums.GrainIdentity.customerType, 'Customer Type', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.daysSupply, 'Days Supply', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.draft, 'Draft', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.entity, 'Group / FC', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.lag, 'Lag', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.metric, 'Metric', Enums.DisplayWidth.XLARGE);
  addKnownGrain(Enums.GrainIdentity.networkCapacityGroup, 'Network Capacity Group', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.networkInventoryGroup, 'Network Inventory Group', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.networkInventoryGroupNetworkPlanning, 'Network Inventory Group', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.node, 'FC', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.nodeGroup, 'FC Group', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.nodeRegion, 'FC Region', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.origin, 'Origin', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.pharmacySite, 'Pharmacy Warehouse', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.planningGroup, 'Planning Group', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.prescriptionType, 'Prescription Type', Enums.DisplayWidth.MEDIUM_SMALL);
  addKnownGrain(Enums.GrainIdentity.processType, 'Process Type', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.productLine, 'Product Line', Enums.DisplayWidth.LARGE);
  addKnownGrain(Enums.GrainIdentity.productLineGroup, 'Product Line Group', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.productLineOwner, 'Product Line Owner', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.scope, 'Scope', Enums.DisplayWidth.MEDIUM_SMALL);
  addKnownGrain(Enums.GrainIdentity.scopeGroup, 'FC Organization', Enums.DisplayWidth.MEDIUM);
  // secondaryScope and secondaryScopeGroup are essentially the same concept, but secondaryScope is only used for the allocaion service and any other usecases
  // should instead use the secondaryScopeGroup grain.
  addKnownGrain(Enums.GrainIdentity.secondaryScope, 'Secondary Scope', Enums.DisplayWidth.MEDIUM_SMALL);
  addKnownGrain(Enums.GrainIdentity.secondaryScopeGroup, 'Secondary FC Organization', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.secondaryNode, 'Secondary FC', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.secondaryNodeGroup, 'Secondary FC Group', Enums.DisplayWidth.MEDIUM);
  addKnownGrain(Enums.GrainIdentity.sortCenter, 'Sort Center', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.sortType, 'Sort Type', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.subMetric, 'Submetric', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.subTeam, 'Subteam', Enums.DisplayWidth.MEDIUM_LARGE);
  addKnownGrain(Enums.GrainIdentity.usageType, 'Usage Type', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.windowType, 'Window Type', Enums.DisplayWidth.SMALL);
  addKnownGrain(Enums.GrainIdentity.feederType, 'Feeder Type', Enums.DisplayWidth.SMALL);
  Object.freeze(KNOWN_GRAIN_MAP);

  ARC_GRAINS.push(KNOWN_GRAIN_MAP.node.id, KNOWN_GRAIN_MAP.secondaryNode.id, KNOWN_GRAIN_MAP.draft.id);
  Object.freeze(ARC_GRAINS);

  GRAIN_TO_GROUPS_MAP[KNOWN_GRAIN_MAP.node.id] = [KNOWN_GRAIN_MAP.nodeGroup, KNOWN_GRAIN_MAP.scopeGroup, KNOWN_GRAIN_MAP.nodeRegion];
  GRAIN_TO_GROUPS_MAP[KNOWN_GRAIN_MAP.productLine.id] = [KNOWN_GRAIN_MAP.businessEntityGroup, KNOWN_GRAIN_MAP.productLineGroup];
  GRAIN_TO_GROUPS_MAP[KNOWN_GRAIN_MAP.secondaryNode.id] = [KNOWN_GRAIN_MAP.secondaryNodeGroup, KNOWN_GRAIN_MAP.secondaryScopeGroup];
  Object.freeze(GRAIN_TO_GROUPS_MAP);

  NON_EDITABLE_GRAINS.push(..._.map([
    KNOWN_GRAIN_MAP.nodeGroup,
    KNOWN_GRAIN_MAP.nodeRegion,
    KNOWN_GRAIN_MAP.scopeGroup,
    KNOWN_GRAIN_MAP.productLineGroup,
    KNOWN_GRAIN_MAP.secondaryNodeGroup,
    KNOWN_GRAIN_MAP.secondaryScopeGroup
  ], 'id'));
  Object.freeze(NON_EDITABLE_GRAINS);

  NON_NATIVE_GRAINS.push(KNOWN_GRAIN_MAP.draft.id, KNOWN_GRAIN_MAP.entity.id, KNOWN_GRAIN_MAP.metric.id);
  Object.freeze(NON_NATIVE_GRAINS);

  window.Grain = Grain;
})();
