/* globals AbstractSourceBasedModel, Enums, Grain, GrainMappingRatioGroup */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'granularity',
    'isRatioSumToOne',
    'mapping',
    'ratioType'
  ]);

  const TEMPLATE = Object.freeze({
    granularity: {
      destinationGrain: [],
      sourceGrain: []
    },
    isRatioSumToOne: false,
    mapping: [],
    ratioType: ''
  });

  class GrainMappingRatio extends AbstractSourceBasedModel {
    constructor (source) {
      super(_.defaults(source, TEMPLATE), PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.granularity.destinationGrain = _.map(this.granularity.destinationGrain, (value) => Grain.known[value]);
      this.granularity.sourceGrain = _.map(this.granularity.sourceGrain, (value) => Grain.known[value]);
      this.mapping = _.map(this.mapping, (ratioMappingGroup) => GrainMappingRatioGroup.create(this.granularity, ratioMappingGroup.sourceKey, ratioMappingGroup.ratioValues));
    }

    isValid () {
      return !_.isEmpty(this.granularity.destinationGrain) &&
        !_.isEmpty(this.granularity.sourceGrain) &&
        !_.isEmpty(this.ratioType) &&
        !_.isEmpty(this.mapping);
    }
  }
  window.GrainMappingRatio = Object.freeze(GrainMappingRatio);
})();
