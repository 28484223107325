/* globals Configuration, Enums */
(function () {
  'use strict';
  class SearchInputController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.input = '';
      this.isHelpOverlayActive = Configuration.activation.isAvailable('GroupBy_nodeRegion');
      $scope.$on(Enums.BroadcastChannel.CLEAR, () => this.input = '');
    }
  }

  angular.module('application.components')
    .component('searchInput', {
      bindings: {
        onValueChange: '&'
      },
      controller: SearchInputController,
      templateUrl: 'templates/components/search-input.component.html'
    });
})();
